
import { defineComponent, onMounted } from "vue";
import KTModalCard from "@/views/modals/Card.vue";
import KTTwoFactorAuthModal from "@/components/modals/wizards/TwoFactorAuthModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "two-factor-auth",
  components: {
    KTModalCard,
    KTTwoFactorAuthModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Two Factor Auth", ["Modals", "Wizards"]);
    });
  },
});
